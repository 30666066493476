import 'owl.carousel';

export default function Videos() {
	$( '#videos-carousel' ).owlCarousel( {
		loop: true,
		nav: true,
		items: 3,
		margin: 30,
		dots: false,
		navText: [
			'<i class="fas fa-angle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			600: {
				items: 2,
				margin: 30
			},
			992: {
				items: 3,
				margin: 30
			}
		}
	} );
	/*$.ajax( {
		url: 'https://www.juicer.io/api/feeds/babyfacevevo',
		method: 'GET',
		dataType: 'json',
		xhrFields: {
			withCredentials: false
		},
		error: () => {
		},
		success: data => {
			const videos_carousel = $( '#videos-carousel' );
			if ( data ) {
				let i = 0;
				let html = '';
				for ( let item of data.posts.items ) {
					html += '<article>';
					html += '<a href="#" data-yt-id="' + item.external_id + '">';
					html += '<img alt="' + item.external_id + '" src="' + item.image + '" />';
					html += item.message.replace( 'Babyface', '<span>Babyface</span>' );
					html += '</a>';
					html += '</article>';

					if ( i === 0 ) {
						$( '#video-player iframe' ).attr( 'src', 'https://www.youtube.com/embed/' + item.external_id );
					}
					i ++;
				}

				videos_carousel.html( html );

				videos_carousel.owlCarousel( {
					loop: false,
					nav: true,
					items: 3,
					margin: 30,
					dots: false,
					navText: [
						'<i class="fa fa-angle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
						'<i class="fa fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
					],
					responsive: {
						0: {
							items: 1,
							margin: 0
						},
						600: {
							items: 2,
							margin: 30
						},
						992: {
							items: 3,
							margin: 30
						}
					}
				} );
			}
		}
	} );*/
}
