export default function Instagram() {
	$.ajax( {
		method: 'GET',
		url: 'https://www.juicer.io/api/feeds/babyface',
		dataType: 'json',
		success: ( data ) => {
			if ( data ) {
				let i = 0;
				let html = '';
				for ( let item of data.posts.items ) {
					html += '<div>';
					html += '<a href="' + item.full_url + '" target="_blank">';
					html += '<img src="' + item.image + '" alt="' + item.id + '" />';
					html += '</a>';
					html += '</div>';
					i ++;
					if ( i >= 8 ) {
						break;
					}
				}

				$( '#instagram' ).html( html );
			}
		},
		error: () => {
			console.log( 'An error has occurred fetching IG photos.' );
		}
	} );
}