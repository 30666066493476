import 'owl.carousel';

export default function Music() {
	$( '#music-carousel' ).owlCarousel( {
		loop: true,
		nav: true,
		items: 1,
		margin: 0,
		dots: false,
		navText: [
			'<i class="fas fa-angle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-angle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );
}
