import Events from './components/events';
import Instagram from './components/instagram';
import Nav from './components/nav';
import Videos from './components/videos';
import Music from './components/music';
import 'magnific-popup';
import AOS from 'aos';

$( document ).ready( function () {
	AOS.init();
	Events();
	Instagram();
	Nav();
	Videos();
	Music();

	$( '#videos article a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const yt_id = $( this ).attr( 'data-yt-id' );
		$( '#video-player iframe' ).attr( 'src', 'https://www.youtube.com/embed/' + yt_id + '?autoplay=1' );
	} );

	$( '.bio-link' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );

	
	if($('#presave').length){
		$.magnificPopup.open({
			items: {
				src: '#presave',
				type: 'inline'
			}
		});
	}

	
});
